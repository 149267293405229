import React from 'react'
import Axios from 'axios'
import { Button, Result, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

import {Config} from './config_section.js'
import './Admin.scss'
import JobAdsStatistics from './JobAdsStatistics'
import Profiles from './Profiles'
import SkillsFramework from './SkillsFramework'
import StudyPrograms from './StudyPrograms'
import Trainings from './Trainings'
import Certifications from './Certifications'
import UserManager from './UserManager'
import VisitorsStatistics from './VisitorsStatistics'

const Admin = ({ showMessage, authData, user, section, setSection, setLoginModalVisible}) => {
  const [usersData, setUsersData] = React.useState(null)
  const [dbCertifications, setDbCertifications] = React.useState(null)
  const [dbStudyPrograms, setDbStudyPrograms] = React.useState(null)
  const [dbTrainings, setDbTrainings] = React.useState(null)
  const [rsSkillsGroup, setRsSkillsGroup] = React.useState(null)
  const [rsSpecificKnowledge, setRsSpecificKnowledge] = React.useState(null)
  const [rsSpecificSkills, setRsSpecificSkills] = React.useState(null)
  const [rsProfiles, setRsProfiles] = React.useState(null)
  /*
  stavy dat:
    init loading = null
    chyba pri loadingu = 0
    prazdne data = []
    plne data = [data]
  */

  React.useEffect(() => {
     if (user && user.admin_level > 1 ) getData()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  function getData () {
    // loading data state
    setDbCertifications(null)
    setDbStudyPrograms(null)
    setDbTrainings(null)
    setRsSkillsGroup(null)
    setRsSpecificKnowledge(null)
    setRsSpecificSkills(null)
    setRsProfiles(null)
    setUsersData(null)
    Axios.post( Config.server.getData, authData, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        if (response && response.data.error === false){
          // success data state
          setDbCertifications(response.data.data.certifications.map(obj=> ({ ...obj, key: obj.id})))
          setDbStudyPrograms(response.data.data.study_programs.map(obj=> ({ ...obj, key: obj.id})))
          setDbTrainings(response.data.data.trainings.map(obj=> ({ ...obj, key: obj.id})))
          setRsSkillsGroup(response.data.data.rs_skill_group.map(obj=> ({ ...obj, key: obj.id})))
          setRsSpecificKnowledge(response.data.data.rs_specific_knowledge.map(obj=> ({ ...obj, key: obj.id})))
          setRsSpecificSkills(response.data.data.rs_specific_skills.map(obj=> ({ ...obj, key: obj.id})))
          setRsProfiles(response.data.data.rs_profiles.map(obj=> ({ ...obj, key: obj.id})))
          setUsersData({
            newUsers: response.data.data.users_data.new_users.map(obj=> ({ ...obj, key: obj.id})),
            blockedUsers: response.data.data.users_data.blocked_users.map(obj=> ({ ...obj, key: obj.id})),
            adminUsers: response.data.data.users_data.admin_users.map(obj=> ({ ...obj, key: obj.id})),
            registeredUsers: response.data.data.users_data.registered_users.map(obj=> ({ ...obj, key: obj.id}))
          })
        } else {
          console.log(response.data.message)
          // error data state
          setDbCertifications(0)
          setDbStudyPrograms(0)
          setDbTrainings(0)
          setRsSkillsGroup(0)
          setRsSpecificKnowledge(0)
          setRsSpecificSkills(0)
          setRsProfiles(0)
          setUsersData(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setDbCertifications(0)
        setDbStudyPrograms(0)
        setDbTrainings(0)
        setRsSkillsGroup(0)
        setRsSpecificKnowledge(0)
        setRsSpecificSkills(0)
        setRsProfiles(0)
        setUsersData(0)
      })
  }

  function dataStatus() {
    if (
      dbCertifications === null &&
      dbStudyPrograms === null &&
      dbTrainings === null &&
      rsSkillsGroup === null &&
      rsSpecificKnowledge === null &&
      rsSpecificSkills === null &&
      rsProfiles === null &&
      usersData === null
    ) {
      return "loading"
    } else if (
      Array.isArray(dbCertifications) &&
      Array.isArray(dbStudyPrograms) &&
      Array.isArray(dbTrainings) &&
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(rsSpecificKnowledge) &&
      Array.isArray(rsSpecificSkills) &&
      Array.isArray(rsProfiles) &&
      typeof usersData === 'object' && usersData !== null
    ) {
      return "ok"
    } else {
      return "error"
    }
  }

  return (
    <section id="admin" className={"section-content"}>
    {
      !user || user.admin_level < 2 ? (
        <div className="vertCenter">
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button type="primary" onClick={()=>setLoginModalVisible(true)}>Log In as Admin</Button>}
          />
        </div>
      ) : dataStatus() === "loading" ? (
        <div className="vertCenter">
          <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : dataStatus() === "error" ? (
        <div className="vertCenter">
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
          />
        </div>
      ) : section === "admin-skills" ? (
        <SkillsFramework refresh={getData} showMessage={showMessage} authData={authData} usersData={usersData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} />
      ) : section === "admin-trainings" ? (
        <Trainings refresh={getData} showMessage={showMessage} authData={authData} usersData={usersData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} dbTrainings={dbTrainings} />
      ) : section === "admin-certs" ? (
        <Certifications refresh={getData} showMessage={showMessage} authData={authData} usersData={usersData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} dbCertifications={dbCertifications} />
      ) : section === "admin-study-programs" ? (
        <StudyPrograms refresh={getData} showMessage={showMessage} authData={authData} usersData={usersData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} dbStudyPrograms={dbStudyPrograms} />
      ) : section === "admin-users" ? (
        <UserManager showMessage={showMessage} authData={authData} usersData={usersData} setUsersData={setUsersData} />
      ) : section === "admin-visitors-stats" ? (
        <VisitorsStatistics showMessage={showMessage} authData={authData} usersData={usersData} />
      ) : section === "admin-job-ads-stats" ? (
        <JobAdsStatistics showMessage={showMessage} rsSkillsGroup={rsSkillsGroup} rsProfiles={rsProfiles} />
      ) : (
        <Profiles refresh={getData} showMessage={showMessage} authData={authData} usersData={usersData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles} />
      )
    }
    </section>
  )
}


export default Admin
