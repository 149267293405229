import React from 'react'
import Axios from 'axios'
import { Col, Divider, Progress, Result, Popover, Row, Spin, Table, Typography } from 'antd'
import { LoadingOutlined, SaveTwoTone } from '@ant-design/icons'

import {Config as ConfigJAA} from '../job-ads-analyzer/config_section.js'
// import {analysis_results} from './analysis_results.js'

const { Link, Title } = Typography


const JobAdsStatistics = ({ showMessage, rsSkillsGroup, rsProfiles }) => {
  const [jobs, setJobs] = React.useState(null)
  const [percent, setPercent] = React.useState({num: 0, message: "Loading..."})
  // const [percent, setPercent] = React.useState({num: 101, message: "Loading..."})
  // const [analysis, setAnalysis] = React.useState(analysis_results)
  const [analysis, setAnalysis] = React.useState(null)
  const analysisIntervalRef = React.useRef(null)
  const analysisShowIntervalRef = React.useRef(null)

  // stop intervals when umnounted earlier than expected
  React.useEffect(() => {
    return () => {
      if (analysisIntervalRef.current) clearInterval(analysisIntervalRef.current)
      if (analysisShowIntervalRef.current) clearInterval(analysisShowIntervalRef.current)
    }
  }, [])

  React.useEffect(() => {
    if (percent.num === 99 && Array.isArray(analysis)) {
      setPercent({num: 100, message: "Analysis done!"}) 
      if (analysisShowIntervalRef.current) clearInterval(analysisShowIntervalRef.current)
      analysisShowIntervalRef.current = setInterval(() => {
        clearInterval(analysisShowIntervalRef.current)
        setPercent((prev) => ({num: 101, message: prev.message}))
      }, 500) // Interval 500ms
    }
  }, [percent.num, analysis])

  React.useEffect(() => {
    setJobs(null)
    Axios.get(ConfigJAA.server.getJobs)
      .then((response) => {
        if (response && response.data.error === false) {
          const data = response.data.data
          setJobs(data.jobs.map(obj=> ({ ...obj, key: obj.id})))

          setAnalysis(null)
          const startTime = Date.now()
          if (analysisIntervalRef.current) clearInterval(analysisIntervalRef.current)
            analysisIntervalRef.current = setInterval(() => {
            const now = Date.now()
            const elapsed = now - startTime
            const estimated = 3500 + 13.6*data.jobs.length
            const remaining = estimated - elapsed
            const remainingSeconds = Math.ceil(remaining / 1000)
            const remainingMinutes = Math.floor(remainingSeconds / 60)
            const remainingSeconds2 = remainingSeconds % 60
            const currentPercent = Math.floor(100 * elapsed / estimated)
            setPercent(() => {
              if (currentPercent < 99) {
                return {num: currentPercent, message: "Estimated time: " + (remainingMinutes ?  + remainingMinutes + " min " : "") + remainingSeconds2 + " sec"}
              } else {
                clearInterval(analysisIntervalRef.current)
                return {num: 99, message: "Just a moment..."}
              }
            })
          }, 100) // Interval 100ms (10 sekund = 100 kroků x 100ms)

          const selectedRowKeys = data.jobs.map(obj=> obj.id)
          // Axios.post( ConfigJAA.server.processPrediction, {keys: selectedRowKeys, result: 'raw'}, {headers: { 'Content-Type': 'application/json' }})
          Axios.post("https://cyberability-platform.informacni-bezpecnost.cz/data/job-ads-analyzer/processPrediction.php", {keys: selectedRowKeys, result: 'raw'}, {headers: { 'Content-Type': 'application/json' }})
          .then((response2) => { 
            if (response2 && response2.data.error === false) {
              setAnalysis(response2.data.data.raw_data)
            } else {
              console.log(response2.data.message)
              setAnalysis(0)
              showMessage({type: 'error', content: "Cannot load the skill analysis data!"})
            }
          })
          .catch((error) => {
            showMessage({type: 'error', content: "Cannot load the skill analysis data!"})
            console.log(error)
            setAnalysis(0)
          })
        } else {
          console.log(response.data.message)
          setJobs(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setJobs(0)
      })

  }, [showMessage])

  const dataStatus = React.useMemo(() => {
    if (jobs === null) {
      return "loading"
    } else if (Array.isArray(jobs)) {
      return "ok"
    } else {
      return "error"
    }
  }, [jobs])

  // ---- exoport to CSV and JSON ----

  function exportToCSV (data, fileName) {
    if (data.length === 0) {
      showMessage({type: 'error', content: "Something went wrong."})
      return
    }

    const csvRows = []
    // Dynamicky generujeme záhlaví CSV na základě klíčů prvního objektu
    const headers = Object.keys(data[0])
    csvRows.push(headers.join(";"))

    // Procházení dat a přidání řádků CSV
    data.forEach((row) => {
      const values = headers.map(header => row[header])
      csvRows.push(values.join(";"))
    })

    // Vytvoření obsahu CSV jako Blob
    const csvString = csvRows.join("\n")
    const blob = new Blob([csvString], { type: "text/csv" })

    // Vytvoření odkazu ke stažení
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", `${fileName}.csv`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) // Odstranění odkazu po kliknutí
  }

  function exportToJSON (data, fileName) {
    if (data.length === 0) {
      showMessage({type: 'error', content: "Something went wrong."})
      return
    }

    // Převod dat na JSON řetězec
    const jsonString = JSON.stringify(data, null, 2)
    const blob = new Blob([jsonString], { type: "application/json" })

    // Vytvoření odkazu ke stažení
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", `${fileName}.json`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) // Odstranění odkazu po kliknutí
  }

  // ---- profiles data computation ----  
  
  const profilePairs = React.useMemo(() => {
    if (!Array.isArray(jobs)) {
      return new Map()
    }
    const profileMap = new Map()

    // Procházení databáze a počítání výskytů
    jobs.forEach(({ enisa_profile, secondary_enisa_profile }) => {
      // Inicializace primárního profilu v mapě
      if (!profileMap.has(enisa_profile)) {
        profileMap.set(enisa_profile, {})
      }
      // Inicializace sekundárního profilu v mapě primárního
      const primaryPairings = profileMap.get(enisa_profile)
      if (!primaryPairings[secondary_enisa_profile]) {
        primaryPairings[secondary_enisa_profile] = 0
      }
      // Zvýšení počtu výskytů
      primaryPairings[secondary_enisa_profile]++

      // Opak pro sekundární profil (abychom pokryli obě strany dvojice)
      if (!profileMap.has(secondary_enisa_profile)) {
        profileMap.set(secondary_enisa_profile, {})
      }
      const secondaryPairings = profileMap.get(secondary_enisa_profile)
      if (!secondaryPairings[enisa_profile]) {
        secondaryPairings[enisa_profile] = 0
      }
      secondaryPairings[enisa_profile]++
    })

    return profileMap
  }, [jobs])
     
  const profileMatchData = dataStatus !== "ok" ? [] : Array.from(profilePairs.keys()).map(profile => {
    const pairings = profilePairs.get(profile)
    return {
      key: profile,
      profile,
      ...Array.from(profilePairs.keys()).reduce((acc, otherProfile) => {
        acc[otherProfile] = pairings[otherProfile] || 0
        return acc;
      }, {}),
    }
  }).filter(row => row.profile !== "")

  // ---- profiles tables data ----

  const profilesCSVNodesData = React.useMemo(() => {
    if (dataStatus !== "ok" || !(profilePairs instanceof Map) || !Array.isArray(jobs)) return []

    // krok 1
    const profileNames = new Set();
    
    profilePairs.forEach((profiles, key) => {
      if (key !== "") profileNames.add(key);
      Object.keys(profiles).forEach((profile) => {
        if (profile !== "") profileNames.add(profile);
      });
    });

    let id = 1;
    const profilesWithId = Array.from(profileNames).map((name) => ({
      id: id++,
      label: name,
      weight: jobs.filter((job) => job.enisa_profile === name || job.secondary_enisa_profile === name).length,
    }));

    return profilesWithId
  }, [dataStatus, profilePairs, jobs])   


  const profilesCSVEdgesData = React.useMemo(() => {
    if (dataStatus !== "ok" || !Array.isArray(rsProfiles) || !profilesCSVNodesData) return []

    // kror 2
    const frequencyMap = new Map();

    profilePairs.forEach((profiles, key) => {
      if (key === "") return; // Ignorování prázdného profilu

      const sourceId = profilesCSVNodesData.find((profile) => profile.label === key)?.id

      Object.keys(profiles).forEach((targetProfile) => {
        if (targetProfile === "") return; // Ignorování prázdného profilu
        const targetId = profilesCSVNodesData.find((profile) => profile.label === targetProfile)?.id
        const weight = profiles[targetProfile]

        if (weight > 0 && sourceId && targetId) {
          const pairKey = `${sourceId}-${targetId}`

          if (frequencyMap.has(pairKey)) {
            const existing = frequencyMap.get(pairKey)
            frequencyMap.set(pairKey, {
              ...existing,
              weight: existing.weight + weight,
            })
          } else {
            frequencyMap.set(pairKey, {
              source: sourceId,
              target: targetId,
              weight,
            })
          }
        }
      })
    })

    return Array.from(frequencyMap.values()).map((e,i) => ({...e, type: 'undirected', id: i}))
  }, [dataStatus, rsProfiles, profilePairs, profilesCSVNodesData])
 

  // ---- skills tables data ----

  const skillsCSVNodesData = React.useMemo(() => {
    if (!Array.isArray(analysis) || !Array.isArray(rsSkillsGroup)) return []

    return rsSkillsGroup.map(skill => {
      let count = 0
      analysis.forEach(row => {
        row.forEach(skillId => {
          if (skillId === skill.id) count += 1
        })
      })
      return {id: skill.id, label: skill.name, weight: count}
    })

  }, [analysis, rsSkillsGroup])

  const skillsCSVEdgesData = React.useMemo(() => {
    if (!Array.isArray(analysis) || !Array.isArray(rsSkillsGroup)) return []

    const frequencyMap = {}
    analysis.forEach((row) => {
      for (let i = 0; i < row.length; i++) {
        for (let j = i + 1; j < row.length; j++) {
          const source = row[i]
          const target = row[j]

          // Generování klíče pro dvojici (zajistíme, že dvojice 1-2 je totéž co 2-1)
          const pairKey = source < target ? `${source}-${target}` : `${target}-${source}`

          // Pokud již existuje, zvýšíme výskyt (weight), jinak inicializujeme
          if (frequencyMap[pairKey]) {
            frequencyMap[pairKey].weight += 1
          } else {
            frequencyMap[pairKey] = { source, target, weight: 1 }
          }
        }
      }
    })
    return Object.values(frequencyMap).map((e,i) => ({...e, type: 'undirected', id: i}))
  }, [analysis, rsSkillsGroup])
  
  // ---- table columns ----

  const profileMatchColumns = [
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'profile',
      align: "center",
      className: "header-column",
      render: value => value === "" ? "Empty" : value
    },
    ...Array.from(profilePairs.keys()).filter(profile => profile !== "").map(profile => ({
      title: profile === "" ? "Empty" : profile,
      dataIndex: profile,
      key: profile,
      align: "center",
      className: "maybe-gray-cell",
      render: (value, row) => {
        const className = row.profile === profile ? 'gray' : '';
        return <span className={className}>{className ? '' : row[profile]}</span>;
      }
    }))
  ]
/*
  const skillConnectionsColumns = [
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      align: "center",
      className: "header-column",
      render: value => value === "" ? "Empty" : value
    },
    ...Array.from(rsSkillsGroup.values()).map(skill => ({
      title: <div className="rotated-header">{skill.name === "" ? "Empty" : skill.name}</div>,
      dataIndex: skill.id,
      key: skill.id,
      align: "center",
      className: "maybe-gray-cell",
    }))
  ]
*/
  const csvNodesColumn = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      sorter:  (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter:  (a, b) => a.weight - b.weight,
    }
  ]

  const csvEdgesColumn = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
    },      
    {
      title: 'source',
      dataIndex: 'source',
      key: 'source',
      sorter:  (a, b) => a.source - b.source,
    },
    {
      title: 'target',
      dataIndex: 'target',
      key: 'target',
      sorter:  (a, b) => a.target - b.target,
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter:  (a, b) => a.weight - b.weight,
    }
  ]

  // ---- return ----

  return (
    <div id="job-ads-statistics">
      <span><Title className="pageTitle">Job Ads Statistics</Title></span>
      {
        dataStatus === "loading" ? (
          <div className="vertCenter"><Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>
        ) : dataStatus === "error" ? (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        ) : (
          <>
            <Divider className="first"><Title level={4}>Profiles Matched in the Ads</Title></Divider>

            <Row gutter={32}>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Nodes
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(profilesCSVNodesData, "profiles - nodes")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(profilesCSVNodesData, "profiles - nodes")}>JSON</Link>
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>

                <Table
                  columns={csvNodesColumn}
                  dataSource={profilesCSVNodesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Edges
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(profilesCSVEdgesData, "profiles - edges")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(profilesCSVEdgesData, "profiles - edges")}>JSON</Link>
                        <br/>--- filtered (CSV) ---<br/>
                        {profilesCSVNodesData.map(profile => <><Link onClick={()=>exportToCSV(profilesCSVEdgesData.filter(e => e.source === profile.id || e.target === profile.id), "profiles - edges - " + profile.id + " - " + profile.label)}>{profile.id + " - " + profile.label}</Link><br/></> )}
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>                    
                
                <Table
                  columns={csvEdgesColumn}
                  dataSource={profilesCSVEdgesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
            </Row>

            <Table
              columns={profileMatchColumns}
              dataSource={profileMatchData}
              pagination={false}
              size="small"
              bordered
              className="profile-match-table"
              rowKey="profile"
            />

            <Divider><Title level={4}>Skills Connection in the Ads</Title></Divider>

            { analysis === 0 ? (
              <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
              />
            ) : analysis === null || percent.num < 101? (
              <div className="vertCenter">
                <Row><Col>
                  <Progress
                    type="dashboard"
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                    percent={percent.num}
                  />
                </Col></Row>
                <Row><Col>{percent.message}</Col></Row>
              </div>
            ) : (
              <>
              {/*
                <Table
                  columns={skillConnectionsColumns}
                  dataSource={[]}
                  pagination={false}
                  size="small"
                  bordered
                  className="skill-connections-table"
                  scroll={{ x: 'max-content' }} // Povolení horizontálního posouvání
                /> 
              */}

                <Row gutter={32}>
                  <Col span={12}>

                    <div className="divider-small">
                      <Title level={5}><>
                        <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Nodes
                        &nbsp;
                        <Popover title="Save to a file" content={
                          <div>
                            --- all ---<br/>
                            <Link onClick={()=>exportToCSV(skillsCSVNodesData, "skills - nodes")}>CSV</Link><br/>
                            <Link onClick={()=>exportToJSON(skillsCSVNodesData, "skills - nodes")}>JSON</Link>
                          </div>
                        }>
                          <SaveTwoTone />
                        </Popover>
                      </></Title>
                    </div>

                    <Table
                      columns={csvNodesColumn}
                      dataSource={skillsCSVNodesData}
                      pagination={false}
                      size="small"
                      bordered
                      className="csv-data-table"
                      rowKey="id"
                    />

                  </Col>
                  <Col span={12}>

                    <div className="divider-small">
                      <Title level={5}><>
                        <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Edges
                        &nbsp;
                        <Popover title="Save to a file" content={
                          <div>
                            --- all ---<br/>
                            <Link onClick={()=>exportToCSV(skillsCSVEdgesData, "skills - edges")}>CSV</Link><br/>
                            <Link onClick={()=>exportToJSON(skillsCSVEdgesData, "skills - edges")}>JSON</Link>
                            <br/>--- filtered (CSV) ---<br/>
                            {rsSkillsGroup.map(skill => <><Link onClick={()=>exportToCSV(skillsCSVEdgesData.filter(e => e.source === skill.id || e.target === skill.id), "skills - edges - " + skill.id + " - " + skill.name)}>{skill.id + " - " + skill.name}</Link><br/></> )}
                          </div>
                        }>
                          <SaveTwoTone />
                        </Popover>
                      </></Title>
                    </div>                    
                    
                    <Table
                      columns={csvEdgesColumn}
                      dataSource={skillsCSVEdgesData}
                      pagination={false}
                      size="small"
                      bordered
                      className="csv-data-table"
                      rowKey="id"
                    />

                  </Col>
                </Row>
              </>
            )}

           
          </>
        )
      }
    </div>
  )
}

export default JobAdsStatistics