import React from 'react'
import { Col, Divider, Result, Row, Slider, Spin, Table, Tooltip, Typography  } from 'antd'
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons'
import Chart from "react-apexcharts"

const { Link, Title } = Typography


const JobStatistics = ({ rsSkillsGroup, jobStatistics, jobs, rsProfiles }) => {
  const [selectedYearRange, setSelectedYearRange] = React.useState([0, 0])
  const [rangeBounds, setRangeBounds] = React.useState([0, 0])
  const [showMoreTopSkills, setShowMoreTopSkills] = React.useState(false)

  React.useEffect(() => {
    if (typeof jobStatistics === 'object' && jobStatistics !== null) {
      const years = Object.keys(jobStatistics)
      setRangeBounds([Math.min(...years), Math.max(...years)])
      setSelectedYearRange([Math.min(...years), Math.max(...years)])
    }
  }, [jobStatistics])

  function dataStatus() {
    if (
      rsProfiles === null &&
      jobs === null &&
      jobStatistics === null &&
      rsSkillsGroup === null
    ) {
      return "loading"
    } else if (
      typeof jobStatistics === 'object' && jobStatistics !== null &&
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(jobs) &&
      Array.isArray(rsProfiles)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }
  
  function countJobs (yearRange) {
    return jobs
    .filter(e => {
      const year = parseInt(e.date.split('-')[0], 10)
      return year >= yearRange[0] && year <= yearRange[1]
    }).length
  }

  function countCountries (yearRange) {
    return jobs
    .filter(e => {
      const year = parseInt(e.date.split('-')[0], 10)
      return year >= yearRange[0] && year <= yearRange[1]
    })
    .reduce((acc, e) => acc.includes(e.country) ? acc : [...acc, e.country], []).length
  }

  function countSkillOccurrences(yearRange) {
    let currentYear = yearRange[0]
    if (currentYear === 0) return []
    let jobCount = 0
    const skillMap = new Map()
  
    while (currentYear <= yearRange[1]) {
      const currentYearData = jobStatistics[currentYear]
      jobCount += currentYearData.job_count
      currentYearData.occurrence.forEach(record => {
        if (skillMap.has(record.skillId)) {
          skillMap.get(record.skillId).occurrence += record.occurrence
        } else {
          skillMap.set(record.skillId, {
            skillId: record.skillId,
            occurrence: record.occurrence
          })
        }
      })
      currentYear++
    }
  
    const output = Array.from(skillMap.values()).map(record => ({
      ...record,
      percentage: parseFloat(((record.occurrence / jobCount) * 100).toFixed(2))
    }));
  
    return output;
  }

  function countProfileOccurrences (yearRange) {
    const arr = jobs.filter(e => {
      const year = parseInt(e.date.split('-')[0], 10)
      return year >= yearRange[0] && year <= yearRange[1]
    })
    return rsProfiles.map(e => {
      const occurrence = arr.filter(job => job.enisa_profile === e.name || job.secondary_enisa_profile === e.name).length
      return { 
        profile: e.name, 
        occurrence: occurrence, 
        percentage: parseFloat((occurrence / arr.length * 100).toFixed(2))
      }
    })
  }

  const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
  const skillsCoverageData = dataStatus() === "ok" ? countSkillOccurrences(selectedYearRange) : null
  const profileDemandData = dataStatus() === "ok" ? countProfileOccurrences(selectedYearRange) : null
  const radarPlotData = dataStatus() !== "ok" ? [] : countSkillOccurrences(rangeBounds).sort((a, b) => b.occurrence - a.occurrence)
 
  const topSkillsColumns = [
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      defaultSortOrder: 'ascend',
      sorter:  (a, b) => rsSkillsGroup.find(e => e.id === a.skillId).name.localeCompare(rsSkillsGroup.find(e => e.id === b.skillId).name),
      render: value => rsSkillsGroup.find(e => e.id === value).name
    },
    {
      title: 'Occurrence',
      dataIndex: 'percentage',
      key: 'percentage',
      width: "90px",
      align: "right",
      className: "maybe-green-cell",
      sorter:  (a, b) =>a.percentage - b.percentage,
      render: value => <Tooltip title={value + " %"} placement="right"><span className={value >= 50 ? "green" : ""}>{value >= 50 ? "High" : value >= 35 ? "Medium" : "Low"}</span></Tooltip>
    }
  ]

  const profileDemandColumns = [
    {
      title: 'ENISA Profile',
      dataIndex: 'profile',
      key: 'profile',
      sorter:  (a, b) => a.profile.localeCompare(b.profile)
    },
    {
      title: 'Occurrence',
      dataIndex: 'percentage',
      key: 'percentage',
      width: "90px",
      align: "right",
      className: "maybe-green-cell",
      defaultSortOrder: 'descend',
      sorter:  (a, b) =>a.percentage - b.percentage,
      render: value => <Tooltip title={value + " %"} placement="right"><span className={value >= 10 ? "green" : ""}>{value >= 10 ? "High" : value >= 5 ? "Medium" : "Low"}</span></Tooltip>
    }
  ]


  return (
    <section className="statistics">
      <span><Title className="pageTitle">Job Ads Statistics</Title></span>
      <div className={dataStatus() === "ok" ? " " : " vertCenter"}>
        {
          dataStatus() === "loading" ? (
            <Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : dataStatus() === "error" ? (
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
            />
          ) : (
            <>
              <div className="slider-title">Select the period to analyze</div>
              <Slider
                range
                className="topSkillsTable"
                value={selectedYearRange}
                onChange={setSelectedYearRange}
                step={1}
                marks={Object.keys(jobStatistics).reduce((acc, year) => ({ ...acc, [year]: year }), {})}
                min={rangeBounds[0]}  
                max={rangeBounds[1]}
              />

           
              <div className="comment">
                The Job Ads Database counts {countJobs(selectedYearRange)} advertisements covering {countCountries(selectedYearRange)} states.{/* as shown in <Link href="/job-ads-analyzer" target="_blank">this map</Link>.*/}
              </div>

              <Row gutter={32}>
                <Col span={12}>

                  <Divider><Title level={4}>{'Skills Coverage in the period ' + selectedYearRange[0] + ' - ' + selectedYearRange[1]}</Title></Divider>

                  <Table 
                    size="small" 
                    pagination={false} 
                    columns={topSkillsColumns} 
                    dataSource={skillsCoverageData.filter(e => e.percentage >= 50 || showMoreTopSkills)} 
                    className="topSkillsTable"  
                    rowKey="skillId"
                  />

                  <div className="showMore">
                    {skillsCoverageData.length > 10 ? (
                      <Link onClick={()=>{
                        setShowMoreTopSkills(showMoreTopSkills ? false : true)
                      }}>
                        {showMoreTopSkills ? <><UpOutlined /> Show less</> : <><DownOutlined /> Show more</>}
                      </Link>
                    ) : ( null )}
                  </div>

                </Col>
                <Col span={12}>

                  <Divider><Title level={4}>ENISA Profiles Demand</Title></Divider>

                  <Table 
                    size="small" 
                    pagination={false} 
                    columns={profileDemandColumns} 
                    dataSource={profileDemandData}
                    className="topSkillsTable " 
                    rowKey="profile"
                  />
                  
                </Col>
              </Row>

              <Divider><Title level={4}>Skills Occurence in the Whole Data Set</Title></Divider>

              <div className="chart-container">
                <Chart
                  type="radar"
                  series={[{
                    name: "Skill occurence",
                    data: radarPlotData.map(e => e.occurrence)
                  }]}
                  options= {{
                    colors: [colors[0]],
                    chart: {
                      toolbar: {
                        show: false
                      },
                    },
                    xaxis: {
                      categories: radarPlotData.map(i => rsSkillsGroup.find(e => e.id === i.skillId).name)
                    },
                  }}
                />
              </div>

            </>
          )
        }
      </div>
    </section>
  )
}

export default JobStatistics